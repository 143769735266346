import ApiSecure from "@/services/api/api-secure";
import ApiDoc from "@/services/api/api-doc";

export function getUserInfo() {
    return ApiSecure.get('user/info/')
}
export function updateData(data) {
    return ApiSecure.post('user/update_data/', data)
}
export function getMonthPremium() {
    return ApiSecure.get('month_premium/')
}
export function getMonthPremiumById(MonthPremiumId) {
    return ApiSecure.get(`month_premium/${MonthPremiumId}/`)
}
export function putMonthPremium(MonthPremiumId, data) {
    return ApiSecure.put(`month_premium/${MonthPremiumId}/`, data)
}
export function getMonthPremiumByManager(formularId) {
    return ApiSecure.get('month_premium/by_manager/', {
        params: {
            formular_id: formularId
        }
    })
}
export function getFileForAccountant(year, month) {
    return ApiDoc.get("month_premium/get_file_for_accountant/", {
        params: { year, month }
    });
}
export function getDepartmentStatusByDate(year, month) {
    return ApiSecure.get('department_status/by_date/', {
        params: { year, month }
    })
}
export function saveFormular(formularId, comment) {
    return ApiSecure.post('formular/save/', { formular_id: formularId, comment })
}

export function downloadExcel(formularId) {
    return ApiDoc.get('formular/get_xlsx_file/', {
        params: {
            formular_id: formularId
        }
    })
}
export function declineFormular(formularId, comment) {
    return ApiSecure.post('formular/reject/', {
        formular_id: formularId,
        comment
    })
}
export function acceptFormular(formularId, comment) {
    return ApiSecure.post('formular/accept/', {
        formular_id: formularId,
        comment
    })
}
export function getDepartments(year, month) {
    return ApiSecure.get('departments/for_month/', {
        params: { month, year }
    })
}

export function getManagersOfDepartment(year, month, department) {
    return ApiSecure.get('formular/by_date_department/', {
        params: { department: department, year, month }
    })
}

export function putFormular(formularId, data) {
    return ApiSecure.put(`formular/${formularId}/`, data)
}

export function getFormular(formularId) {
    return ApiSecure.get(`formular/${formularId}/`)
}

export function getFormularHistory(formularId) {
    return ApiSecure.get(`formular/status_history/`, {
        params: { formular_id: formularId }
    })
}

export function setEfficiencyCoefficient(year, month, departmentId, efficiencyCoefficient) {
    return ApiSecure.post('departments/set_efficiency/', {
        year, month, department_id: departmentId, efficiency_coef: efficiencyCoefficient
    })
}

export function sign(year, month, departmentId) {
    return ApiSecure.post('departments/sign/', {
        year, month, department_id: departmentId
    })
}

export function getInlineDocument({year, month, departmentId}, onDownloadProgress) {
    const params = new URLSearchParams();
    params.append("year", year);
    params.append("month", month);
    params.append("department_id", departmentId);
    const config = {
        params,
        onDownloadProgress,
    };
    return ApiSecure.get(`departments/file_inline/`, config)
}

export function downloadDepartmentFile(year, month, departmentId) {
    const params = new URLSearchParams();
    params.append("year", year);
    params.append("month", month);
    params.append("department_id", departmentId);
    return ApiDoc.get("departments/file/", {
        params
    });
}