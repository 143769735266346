// State initial object
import {getUserInfo} from "@/services/api/requests";

const initialState = () => ({
	id: null,
	role: null,
	department: null,
	job_position: null,
	personal_number: null,
	user_name: null,
	viewPermissions: [],
	credentials: {
		login: "",
		password: "",
		newPassword: "",
		confirmationPassword: "",
		captcha: "",
		code: "",
	}
});


/* Module .store.js */


// VUEX STATE
const state = initialState();


// VUEX GETTERS
const getters = {
	getUser: (state) => state,
	getRole: (state) => state.role,
	getPermissions: (state) => state.viewPermissions,
	getLogin: (state) => state.login,
	getPassword: (state) => state.password,
	getCredentialsLogin: (state) => state.credentials.login,
	getCredentialsPassword: (state) => state.credentials.password,
	getCredentialsNewPassword: (state) => state.credentials.newPassword,
	getCredentialsConfirmationPassword: (state) => state.credentials.confirmationPassword,
	getCredentialsCode: (state) => state.credentials.code,
	isLoggedIn: (state) => !!state.role,
	isAdmin1: (state) => state.role === 'Администратор 1',
	isAdmin2: (state) => state.role === 'Администратор 2',
	isManager1: (state) => state.role === 'Менеджер 1',
	isManager2: (state) => state.role === 'Менеджер 2',
	isHeadOfDepartment: (state) => state.role === 'Руководитель подразделения',
	getName: (state) => state.user_name,
	getId: (state) => state.id,
	getDepartment: (state) => state.department,
	getJobPosition: (state) => state.job_position,
	getPersonalNumber: (state) => state.personal_number,
};


// VUEX ACTIONS
const actions = {
	reset({commit}) {
		commit('RESET');
	},
	async init({ commit }) {
		commit('SET', await getUserInfo());
	},
};


// VUEX MUTATIONS
const mutations = {
	RESET(state) {
		const newState = initialState();
		Object.keys(newState).forEach(key => {
			state[key] = newState[key]
		});
	},
	SET(state, profile) {
		state.role = profile.role;
		state.id = profile.id;
		state.department = profile.department;
		state.job_position = profile.job_position;
		state.personal_number = profile.personal_number;
		state.user_name = profile.user_name;
	},
	LOGIN(state, payload) {
		state.credentials = {
			...state.credentials,
			login: payload
		};
	},
	PASSWORD(state, payload) {
		state.credentials = {
			...state.credentials,
			password: payload
		};
	},
	NEW_PASSWORD(state, payload) {
		state.credentials = {
			...state.credentials,
			newPassword: payload
		};
	},
	CONFIRMATION_PASSWORD(state, payload) {
		state.credentials = {
			...state.credentials,
			confirmationPassword: payload
		};
	},
	CODE(state, payload) {
		state.credentials = {
			...state.credentials,
			code: payload
		};
	},
};


export default {
	state,
	getters,
	actions,
	mutations
};