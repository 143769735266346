<script>
import {mapGetters, mapState} from "vuex";
import {mdiLogout, mdiCalendar, mdiAccountCircleOutline} from "@mdi/js";
export default {
  name: "App",
  components: {
    Snackbar: () => import("@/components/Snackbar"),
  },
  data: function () {
    return {
      mdiLogout,
      mdiAccountCircleOutline,
      mdiCalendar
    };
  },
  computed: {
    ...mapState("App", { isRouterLoading: 'isRouterLoading' }),
    ...mapGetters("App", { isScreenSmall: 'isScreenSmall' }),
    ...mapGetters("User", {
      isLoggedIn: 'isLoggedIn',
      userRole: 'getRole',
      userName: 'getName',
      userJobPosition: 'getJobPosition',
      userDepartment: 'getDepartment',
      userPersonalNumber: 'getPersonalNumber',
      userId: 'getId',
    }),
  },
  created () {
    this.onResize();
  },
  methods: {
    onResize () {
      this.$store.commit("App/MENUS", false);
      setImmediate(() => {
        this.$store.commit("App/ON_RESIZE");
        this.$store.commit("App/MENUS", true);
      });
    },
    logout() {
      this.$auth.logout()
    }
  },
}
</script>

<template>
  <v-app v-resize="onResize">
    <v-app-bar
      v-if="$route.fullPath !== '/auth' && isLoggedIn"
      app
      absolute
      flat
      min-height="56px"
      max-height="64px"
      style="border-bottom: #d2d2d7 solid 2px !important;"
    >
      <router-link to="/">
        <v-img
          alt="VSM Premium"
          src="@/assets/logo/logo_c.png"
          width="100"
          contain
        />
      </router-link>
      <strong
        class="ml-4"
        style="color: #666666; font-size: 14px"
      >РАСЧЕТ ПРЕМИЙ</strong>
      <v-spacer />
      <v-menu
        v-if="isLoggedIn"
        bottom
        offset-y
        content-class="white"
      >
        <template #activator="{on, attrs}">
          <v-btn
            icon
            depressed
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>
              {{ mdiAccountCircleOutline }}
            </v-icon>
          </v-btn>
        </template>
        <v-list three-line>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>
                <strong>Пользователь</strong>
                <v-chip
                  v-if="userPersonalNumber"
                  small
                  label
                  class="ml-2"
                >
                  #{{ userPersonalNumber }}
                </v-chip>
              </v-list-item-subtitle>
              <v-list-item-title>
                {{ userName }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ userRole }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>
                <strong>Должность и обособленное подразделение</strong>
              </v-list-item-subtitle>
              <v-list-item-title>
                <template v-if="userJobPosition && userJobPosition !== 'nan'">
                  {{ userJobPosition }}
                </template>
                <span
                  v-else
                  class="font-italic"
                >Позиция не указана</span>
              </v-list-item-title>
              <v-list-item-subtitle>
                <template v-if="userDepartment && userDepartment !== 'nan'">
                  {{ userDepartment }}
                </template>
                <span
                  v-else
                  class="font-italic"
                >Подразделение не указано</span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider />
        <v-list>
          <v-list-item
            v-if="$route.name !== 'Main'"
            @click="$router.push('/')"
          >
            <v-list-item-avatar :size="24">
              <v-icon>
                {{ mdiCalendar }}
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                На главную
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            @click="logout"
          >
            <v-list-item-avatar :size="24">
              <v-icon>
                {{ mdiLogout }}
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                Выйти
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <template  
      v-if="isRouterLoading"
    >
      <v-main>
        <v-overlay>
          <v-progress-circular
            indeterminate
            size="64"
          />
        </v-overlay>
      </v-main>
    </template>
    <template v-else>
      <Snackbar />
      <v-main>
        <router-view
          :key="$route.fullPath"
          name="content"
        />
      </v-main>
    </template>
  </v-app>
</template>