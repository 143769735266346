const routes =
    [
        {
            path: "/",
            name: "Main",
            components: {
                content: () => import("@/views/Start"),
            },
            meta: {
                title: "pages.main.title",
                public: false,
            }
        },
        {
            path: "/auth",
            name: "Auth",
            components: {
                content: () => import("@/views/Auth"),
            },
            meta: {
                title: "pages.auth.title",
                public: true,
            }
        },
        {
            path: "/formular/:formularId",
            name: "Formular",
            components: {
                content: () => import("@/views/Formular.vue"),
            },
            props: { content: true },
            meta: {
                title: "pages.table.title",
                public: false,
            }
        },
        {
            path: "/departments",
            name: "Departments",
            components: {
                content: () => import("@/views/Departments.vue"),
            },
            props: { content: true },
            meta: {
                title: "pages.departments.title",
                public: false,
            }
        },
        {
            path: "/department",
            name: "Department",
            components: {
                content: () => import("@/views/Department.vue"),
            },
            props: { content: true },
            meta: {
                title: "pages.department.title",
                public: false,
            }
        },
        {
            path: "/404",
            name: "NotFound",
            components: {
                content: () => import("@/views/404"),
            },
            meta: {
                public: true,
                title: "pages.404.title",
            }
        },
        {
            path: "*",
            redirect: "/404"
        }
    ];

export default routes