import axios from "axios";
import router from "@/router";
import storage from "@/services/web-storage";
import store from "@/store";

const API_URL = process.env.VUE_APP_API_BASE_URL + "auth/";
console.log(API_URL)
class AuthService {
    #refreshingToken= false;
    #uid =  this.generateDeviceId();
    generateDeviceId() {
        const navigatorInfo = window.navigator;
        const screenInfo = window.screen;
        let uid = navigatorInfo.userAgent.replace(/\D+/g, "");
        uid += screenInfo.height || "";
        uid += screenInfo.width || "";
        uid += screenInfo.pixelDepth || "";
        return uid;
    }
    async checkAccessToken() {
        if (this.#refreshingToken) {
            return new Promise(resolve => {
                setTimeout(async () => {
                    resolve(await this.checkAccessToken())
                }, 100);
            });
        }
        if (await this.isAccessValid())
            return storage.getAccessToken();
        else
            return null;
    }
    async isAccessValid() {
        return storage.getAccessToken()
          && storage.getAccessExpiry()
          && (Date.now() < storage.getAccessExpiry())
          || await this.token_refresh();
    }
    async token_refresh() {
        if (this.isRefreshValid()) {
            this.#refreshingToken = true;
            const res = await this.authentication({
                url: API_URL + "refresh/",
                data: {
                    refresh: storage.getRefreshToken()
                }
            });
            this.#refreshingToken = false
            return res;
        }
        else return false;
    }
    isRefreshValid() {
        return storage.getRefreshToken()
          && storage.getRefreshExpiry()
          && (Date.now() < storage.getRefreshExpiry());
    }
    async authentication({url, data, config}) {
        try {
            const response = await axios.post(API_URL + url, data, config);
            console.log(response)
            if (response.status === 200) {
                await this.onLoginSuccess(response.data);
                return true;
            } else {
                return false;
            }
        } catch (e) {
            return false;
        }
    }
    async onLoginSuccess(data) {
        const accessToken = data.accessToken
        const refreshToken = data.refreshToken
        const accessTokenExpiresIn = data.accessTokenExpiresIn
        const refreshTokenExpiresIn = data.refreshTokenExpiresIn

        if (accessToken) {
            storage.setAccessToken(accessToken);
            const lifespan = accessTokenExpiresIn * 1000 - 5000;
            storage.setAccessExpiry(Date.now() + lifespan);
        }
        if (refreshToken) {
            storage.setRefreshToken(refreshToken);
            const lifespan = refreshTokenExpiresIn * 1000 - 5000;
            storage.setRefreshExpiry(Date.now() + lifespan);
        }

        await store.commit("User/SET", data)
    }
    async login({ email, code }) {
        return await this.authentication({
            url: "login/",
            data: { email, code, deviceId: this.#uid }
        });
    }

    async code(email) {
        try {
            const response = await axios.post(API_URL + "code/",  {
                email: email,
                deviceId: this.#uid
            });
            return response?.status === 200;
        } catch (e) {
            return false;
        }
    }
    async logout() {
        // const token = storage.getAccessToken();
        // if (token)
        //     await axios.post(API_URL + "logout/", undefined, {
        //         headers: { Authorization: `Bearer ${token}` }
        //     }); //server
        await storage.clear(); //localstore
        await store.dispatch("reset", undefined, { root: true }); //vuex
        router.go(0);
    }
}
export default new AuthService();